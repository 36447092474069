import { FictionalCompany, MonthlyBill, UserGovAccount } from "@models";
import { BaseAction, IDeclaration, IBillMonthly } from "types";

export const Types = {
  PURGE: "contributions/PURGE",
  CONTRIBUITION_LOADING: "contributions/LOADING",
  GET_CNPJ_REQUEST: "contributions/GET_CNPJ_REQUEST",
  GET_CNPJ_SUCCESS: "contributions/GET_CNPJ_SUCCESS",
  GET_CNPJ_ERROR: "contributions/GET_CNPJ_ERROR",
  GET_BILL_MONTHLY_REQUEST: "contributions/GET_BILL_MONTHLY_REQUEST",
  GET_BILL_MONTHLY_SUCCESS: "contributions/GET_BILL_MONTHLY_SUCCESS",
  GET_BILL_MONTHLY_ERROR: "contributions/GET_BILL_MONTHLY_ERROR",
  DECLARE_ANUAL_REQUEST: "contributions/DECLARE_ANUAL_REQUEST",
  DECLARE_ANUAL_SUCCESS: "contributions/DECLARE_ANUAL_SUCCESS",
  DECLARE_ANUAL_ERROR: "contributions/DECLARE_ANUAL_ERROR",
  DECLARE_TRUE_REQUEST: "contributions/DECLARE_TRUE_REQUEST",
  DECLARE_TRUE_SUCCESS: "contributions/DECLARE_TRUE_SUCCESS",
  DECLARE_TRUE_ERROR: "contributions/DECLARE_TRUE_ERROR",
};

// interface MonthBill {
//   Apurado: string;
//   dateAcolhimento: string;
//   principal: string;
//   mult: string;
//   juro: string;
//   total: string;
//   dateV: string;
//   dateAco: string;
// }

export interface IFictionalCompanyEntry {
  cnpj: string;
  pathname: string;
}

interface ContributionState {
  company: FictionalCompany;
  error?: string;
  empresarial_name: string;
  govAccount: UserGovAccount;
  months_bills: MonthlyBill[];
  anual_bills: MonthlyBill[];
  declaration_year: IDeclaration;
}
export interface IbillFromYear {
  year: number;
  declaration: IDeclaration;
  fictionalCompanyId: number;
}
const INITIAL_STATE: ContributionState = {
  company: null,
  empresarial_name: "",
  govAccount: null,
  months_bills: [],
  anual_bills: [],
  error: "",
  declaration_year: null,
};

const contributions = (
  state = INITIAL_STATE,
  { type, payload }: BaseAction
) => {
  switch (type) {
    case Types.PURGE:
      return INITIAL_STATE;
    case Types.GET_CNPJ_SUCCESS:
      return {
        ...state,
        company: payload.fictional_company,
        govAccount: payload.gov_account,
        empresarial_name: payload.fictional_company.company_name,
      };
    case Types.GET_CNPJ_ERROR:
      return { ...state, error: payload };

    case Types.DECLARE_ANUAL_SUCCESS:
      return { ...state, anual_bills: payload };
    case Types.DECLARE_TRUE_SUCCESS:
      return { ...state, declaration_year: payload };
    case Types.DECLARE_TRUE_ERROR:
      return { ...state, error: payload };
    case Types.GET_BILL_MONTHLY_SUCCESS:
      return { ...state, month_bills: payload };
    default:
      return state;
  }
};

export const Creators = {
  getFictionalCompany: ({
    cnpj,
    pathname,
  }: IFictionalCompanyEntry): BaseAction<IFictionalCompanyEntry> => ({
    type: Types.GET_CNPJ_REQUEST,
    payload: { cnpj, pathname },
  }),
  getBillMonthly: ({
    fictionalCompanyId,
    year,
  }: IBillMonthly): BaseAction<IBillMonthly> => ({
    type: Types.GET_BILL_MONTHLY_REQUEST,
    payload: { fictionalCompanyId, year },
  }),
  billsFromYear: ({
    declaration,
    year,
    fictionalCompanyId,
  }: IbillFromYear): BaseAction<IbillFromYear> => ({
    type: Types.DECLARE_ANUAL_REQUEST,
    payload: { year, declaration, fictionalCompanyId },
  }),
  declareAnualTrue: ({
    year,
    declaration,
    fictionalCompanyId,
  }: IbillFromYear): BaseAction<IbillFromYear> => ({
    type: Types.DECLARE_TRUE_REQUEST,
    payload: { year, declaration, fictionalCompanyId },
  }),
};
export default contributions;
