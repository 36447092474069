import React from "react";
import { IMaskInput } from "react-imask";

const InputMask = (props: any) => {
  const { mask, inputRef, ...other } = props;

  return (
    <IMaskInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      radix="."
      unmask
      mask={mask}
      placeholderChar={"\u2000"}
    />
  );
};
export default InputMask;
