import { BaseAction } from "types";
import { put, call } from "redux-saga/effects";
import {
  PayBillData,
  Types as tableMonthlyTypes,
} from "@store/ducks/table_monthly";
import { ApiResponse } from "apisauce";
import { toast } from "react-toastify";
import { ErrorException } from "../../models/ErrorException";
import { ContributionsService } from "../../services/api";

interface Response {
  message: string;
}
export function* generateDataTable() {
  try {
    yield put({ type: tableMonthlyTypes.SHOW_TABLE, payload: true });
  } catch (err) {
    if (err instanceof Error) {
      toast.error(err.message);
    }
  }
}

export function* payBill({ payload }: BaseAction<PayBillData>) {
  const { billsSelected } = payload;
  const billIds = billsSelected.map((el) => el.id);

  try {
    const response: ApiResponse<Response> = yield call(
      ContributionsService.payBill,
      billIds
    );

    if (response.status >= 200 && response.status < 300) {
      toast.success(response.data.message);
    } else {
      throw new ErrorException("Erro no sistema");
    }
  } catch (err) {
    if (err instanceof Error) {
      toast.error(err.message);
    }
  }
}
