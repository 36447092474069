import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import {
  Card as CardMaterial,
  CardContent,
  Typography,
  makeStyles,
  Theme,
  CardActionArea,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "15em",
    height: "12em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  media: {
    height: 0,
    paddingTop: "26.25%", // 16:9
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pos: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface CardProps {
  src: any;
  title: string;
  url: string;
}

const RouterLink = forwardRef((props: any) => {
  return (
    <div style={{ flexGrow: 1 }}>
      <NavLink {...props} />
    </div>
  );
});
const Card = ({ src, title, url }: CardProps) => {
  const classes = useStyles();
  return (
    <CardActionArea
      component={RouterLink}
      to={url}
      style={{ textDecoration: "none" }}
    >
      <CardMaterial
        variant="outlined"
        className={classes.root}
        style={{ backgroundColor: "#fefefe" }}
      >
        <CardContent style={{ alignSelf: "center" }}>
          <div className={classes.pos}>
            <img src={src} alt={title} width={40} height={40} />
          </div>

          <Typography
            variant="body2"
            component="p"
            style={{
              fontSize: "0.8em",
              fontWeight: 400,
              margin: 0,
              textAlign: "justify",
            }}
          >
            {title}
          </Typography>
        </CardContent>
      </CardMaterial>
    </CardActionArea>
  );
};
export default Card;
