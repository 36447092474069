import { combineReducers } from "redux";
import { User } from "@models";
import { ApiResponse } from "apisauce";
import { put } from "redux-saga/effects";
import { ErrorException } from "../../models/ErrorException";
import {
  session,
  table_monthly,
  session_gov,
  form_mei,
  contribuitions,
} from "../ducks";

export const rootReducer = combineReducers({
  session,
  session_gov,
  table_monthly,
  form_mei,
  contribuitions,
});

export type RootReducerType = ReturnType<typeof rootReducer>;

/**
 *  this function works like a middleware between response and  Redux state
 * @param  response response from request
 * @param  payload  data to put in redux-saga effect
 * @param  type     type to put effect redux-saga
 * @param  callback that function will run after type of action
 */
export function* sucessCallback<T, U>(
  response: ApiResponse<T, User> | any,
  payload: U | User,
  type: string,
  callback?: () => void
) {
  if (response.status >= 200 && response.status < 300) {
    if (callback) {
      if (isGenerator(callback)) {
        yield put({ type, payload });
        yield callback();

        // yield put({ type: type, payload: payload });
      } else {
        yield put({ type, payload });
        callback();
      }
    } else {
      yield put({ type, payload });
    }
  } else {
    throw new ErrorException(response.data.message);
  }
}

// check function type
function isGenerator(target) {
  return target[Symbol.toStringTag] === "GeneratorFunction";
}
