import { RootReducerType } from "@store/modules/rootReducer";
import React, { Fragment } from "react";
import {
  Redirect,
  Route,
  RouteProps as ReactDOMRouteprops,
} from "react-router-dom";
import { useSelector } from "react-redux";

interface RouteProps extends ReactDOMRouteprops {
  layout: React.ElementType;
}
const RouteLoginGov = ({
  layout: Layout,
  component: Component,
  ...rest
}: RouteProps) => {
  const { isAuthenticated } = useSelector(
    (state: RootReducerType) => state.session
  );

  return (
    <>
      {isAuthenticated ? (
        <Layout>
          <Route render={(rest) => <Component {...rest} />} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
      )}
    </>
  );
};
export default RouteLoginGov;
