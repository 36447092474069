import React, { createContext, useContext, useState } from "react";

interface NameContextData {
  name: string;
  setName: React.Dispatch<any>;
}

const NameContext = createContext({} as NameContextData);

const NameProvider: React.FC = ({ children }) => {
  const [name, setName] = useState<string>("");
  return (
    <NameContext.Provider value={{ name, setName }}>
      {children}
    </NameContext.Provider>
  );
};

const useName = () => {
  const context = useContext(NameContext);
  if (!context) throw new Error("UseName msut be used within a Provdider");
  const { name, setName } = context;
  return { name, setName };
};

export { NameProvider, useName };
