import React from "react";
import { LayoutProps } from "../interfaces";

const EmptyLayout = ({ children }: LayoutProps) => {
  return (
    <div>
      <main>{children}</main>
    </div>
  );
};
export default EmptyLayout;
