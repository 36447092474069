export class ErrorException {
  private message: string;

  constructor(message: string) {
    this.message = message;
  }

  get Message(): string {
    return this.message;
  }
}
