import { createBrowserHistory } from "history";
import { FictionalCompany } from "@models";
import { IRouteMessage } from "types";

declare global {
  interface Window {
    dataLayer: any;
  }
}
// type LocationProps = Location<IRouteMessage>;

const history = createBrowserHistory<IRouteMessage<FictionalCompany | any>>();

export default history;
