import { put, call } from "redux-saga/effects";
import { BaseAction, Error, ILoginType } from "types";
import { sucessCallback } from "@store/modules/rootReducer";
import { ApiResponse } from "apisauce";
import { Types as sessionTypes } from "@store/ducks/session";
import { Types as tableMonthlyTypes } from "@store/ducks/table_monthly";
import { Types as sessionGovTypes } from "@store/ducks/session_gov";
import { Types as contributionsTypes } from "@store/ducks/contributions";
import { Types as formMeiTypes } from "@store/ducks/form_mei";
import { User } from "@models";
import { toast } from "react-toastify";
import history from "@history";
import { AuthenticationService } from "../../services/api";

interface IResponse extends Error {
  readonly user: User;
  readonly token: string;
}

export function* authorize({ payload }: BaseAction<ILoginType>) {
  try {
    const response: ApiResponse<IResponse> = yield call(
      AuthenticationService.login,
      payload
    );

    const token = function saveToken() {
      localStorage.setItem("token", response.data.token);
    };

    const decrypt = function getToken() {
      localStorage.getItem(atob("token"));
    };

    yield sucessCallback(
      response,
      response.data.user,
      sessionTypes.SESSION_SUCCESS,
      token
    );

    localStorage.setItem("@machineId", payload.id);
    toast.success("Autenticado com sucesso");
    history.push("/servicos");
  } catch (error) {
    toast.error(error);
    yield put({ type: sessionTypes.SESSION_ERROR, payload: error });
  }
}

export function authorization() {
  try {
    const token = localStorage.getItem(btoa("token"));
    if (token === null) return null;

    const decrypt = atob(token);

    const config = {
      Authorization: `Bearer ${decrypt}`,
    };

    return config;
  } catch (err) {
    toast.error("Token não encontrado!");
    return null;
  }
}

export function* logout() {
  try {
    localStorage.removeItem("token");
    yield put({ type: sessionTypes.PURGE });
    yield put({ type: sessionGovTypes.PURGE });
    yield put({ type: contributionsTypes.PURGE });
    yield put({ type: tableMonthlyTypes.PURGE });
    yield put({ type: formMeiTypes.PURGE });
    history.push("/");
  } catch (error) {
    yield put({ type: sessionTypes.SESSION_ERROR });
    yield put({ type: sessionGovTypes.SESSION_ERROR });
  }
}
