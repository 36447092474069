import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { Reducer } from "redux";

// used for persist data in localStorage
export default (reducers: Reducer) => {
  const persistedReducer = persistReducer(
    {
      key: "root",
      storage: storageSession,
      whitelist: ["session", "contribuitions", "session_gov", "form_mei"],
    },
    reducers
  );
  return persistedReducer;
};
