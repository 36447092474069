import { BaseAction, ILoginType } from "types";
import { User } from "@models";

export const Types = {
  PURGE: "session/PURGE",
  SESSION_LOADING: "session/LOADING",
  SESSION_REQUEST: "session/SESSION_REQUEST", // LOGIN
  SESSION_SIGNUP_REQUEST: "session/SESSION_SIGNUP_REQUEST",
  SESSION_ERROR: "session/SESSION_ERROR",
  SESSION_LOGOUT: "session/SESSION_LOGOUT",
  SESSION_SUCCESS: "session/SESSION_SUCCESS",
};
interface SessionState {
  user: User | null; // change to type User
  loading: boolean;
  isAuthenticated: boolean | null;
  error?: string;
}
const INITIAL_STATE: SessionState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  error: "",
};

const session = (state = INITIAL_STATE, { type, payload }: BaseAction) => {
  switch (type) {
    case Types.PURGE:
      return INITIAL_STATE;
    case Types.SESSION_LOADING:
      return { ...state, loading: true };
    case Types.SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { id: payload.id },
        isAuthenticated: true,
        error: "",
      };

    case Types.SESSION_ERROR:
      return { ...state, loading: false, error: payload, user: null };

    default:
      return state;
  }
};
export const Creators = {
  authorize: ({
    password,
    username,
    id,
  }: ILoginType): BaseAction<ILoginType> => ({
    type: Types.SESSION_REQUEST,
    payload: { username, password, id },
  }),
  register: ({
    password,
    username,
    id,
  }: ILoginType): BaseAction<ILoginType> => ({
    type: Types.SESSION_SIGNUP_REQUEST,
    payload: { username, password, id },
  }),
  authorization: (): BaseAction => ({
    type: Types.SESSION_REQUEST,
  }),
  logout: (): BaseAction => ({
    type: Types.SESSION_LOGOUT,
  }),
};
export default session;
