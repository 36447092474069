/**
 *
 *  Reducer Programa Gerador de DAS e Microempreendedor Individual
 *   --- CONTRIBUIÇÃO MENSAL---
 *    Somente mostra o calendário e a tabela com os meses daquele ano
 */
import { MonthlyBill } from "@models";
import { BaseAction } from "types";

export const Types = {
  PURGE: "calendar/PURGE",
  SHOW_CALENDAR: "calendar/SHOW_CALENDAR",
  SHOW_TABLE: "calendar/SHOW_TABLE",
  SAVE_DATA_TABLE_REQUEST: "calendar/SAVE_DATA_TABLE_REQUEST",
  SAVE_DATA_TABLE_SUCCESS: "calendar/SAVE_DATA_TABLE",
  SELECT_LINE_BILL: "calendar/SELECT_LINE_BILL",
  PAY_SELECTED_LINE_BILL: "calendar/PAY_SELECT_LINE_BILL",
};

export interface PayBillData {
  billsSelected: MonthlyBill[];
}
interface State {
  calendar: boolean;
  table: boolean;
  tableData: MonthlyBill[];
  selectedRowTable: MonthlyBill[];
}
const INITIAL_STATE: State = {
  calendar: false, // show the page for select a year and load table
  table: false, // show table
  tableData: [], // data table
  selectedRowTable: [], // linhas selecionadas da tabela  para pagamento de contribuição mensal
};
const calendar = (state = INITIAL_STATE, { type, payload }: BaseAction) => {
  switch (type) {
    case Types.PURGE:
      return INITIAL_STATE;
    case Types.SELECT_LINE_BILL:
      return { ...state, selectedRowTable: payload };
    case Types.SHOW_CALENDAR:
      return { ...state, calendar: payload };
    case Types.SHOW_TABLE:
      return { ...state, table: payload };
    case Types.SAVE_DATA_TABLE_SUCCESS:
      return { ...state, tableData: payload };
    default:
      return { ...state };
  }
};
export const Creators = {
  showCalendar: (payload: boolean) => (dispatch) => {
    dispatch({ type: Types.SHOW_CALENDAR, payload });
  },
  generateDataTable: (): BaseAction => ({
    type: Types.SAVE_DATA_TABLE_REQUEST,
  }),

  // saveDataDasn: (payload?: string, yearName: boolean = false) => (dispatch) => {
  //   const data = dateBuilder(payload, yearName);
  //   dispatch({ type: Types.SAVE_DATA_TABLE, payload: data });
  // },
  purgeTableData: () => (dispatch) => {
    dispatch({ type: Types.PURGE });
  },
  // test selectedRowData
  selectedRowTableData: (payload: MonthlyBill[]) => (dispatch) => {
    dispatch({ type: Types.SELECT_LINE_BILL, payload });
  },
  payBill: ({ billsSelected }: PayBillData): BaseAction<PayBillData> => ({
    type: Types.PAY_SELECTED_LINE_BILL,
    payload: { billsSelected },
  }),
};
export default calendar;
