import { BaseAction, IGovLoginType, IGovRegisterType } from "types";
import { UserGovAccount } from "@models";

export const Types = {
  PURGE: "sessionGov/PURGE",
  SESSION_LOADING: "sessionGov/LOADING",
  SESSION_AUTH_REQUEST: "sessionGov/SESSION_AUTH_REQUEST", // LOGIN
  SESSION_SIGNUP_REQUEST: "sessionGov/SESSION_SIGNUP_REQUEST",
  SESSION_SIGNUP_SUCCESS: "sessionGov/SESSION_SIGNUP_SUCCESS",
  SESSION_ERROR: "sessionGov/SESSION_ERROR",
  SESSION_LOGOUT: "sessionGov/SESSION_LOGOUT",
  SESSION_SUCCESS: "sessionGov/SESSION_SUCCESS",
};
interface SessionGov {
  isAuthenticated: boolean;
  userGov?: UserGovAccount | null;
  error?: string;
}

const INITIAL_STATE: SessionGov = {
  isAuthenticated: false,
  userGov: null,
  error: "",
};

const sessionGov = (state = INITIAL_STATE, { type, payload }: BaseAction) => {
  switch (type) {
    case Types.PURGE:
      return INITIAL_STATE;
    case Types.SESSION_LOADING:
      return { ...state, loading: true };
    case Types.SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        userGov: payload,
        isAuthenticated: true,
        error: "",
      };
    case Types.SESSION_SIGNUP_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case Types.SESSION_ERROR:
      return { ...state, loading: false, error: payload, userGov: null };

    case Types.SESSION_LOGOUT:
      return { ...state, loading: false, userGov: null };

    default:
      return state;
  }
};
export const Creators = {
  authorizeGov: (data: IGovLoginType, pathName = "/servicos"): BaseAction => ({
    type: Types.SESSION_AUTH_REQUEST,
    payload: { data, pathName },
  }),
  registerGov: (
    data: Partial<IGovRegisterType>,
    pathName: string
  ): BaseAction => ({
    type: Types.SESSION_SIGNUP_REQUEST,
    payload: { data, pathName },
  }),

  logout: (): BaseAction => ({
    type: Types.SESSION_LOGOUT,
  }),
};
export default sessionGov;
