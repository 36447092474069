import React, { createContext, useContext, useState } from "react";

interface CPFContextData {
  cpf: string;
  setCpf: React.Dispatch<any>;
}

const CpfContext = createContext({} as CPFContextData);

const CpfProvider: React.FC = ({ children }) => {
  const [cpf, setCpf] = useState<string>("");
  return (
    <CpfContext.Provider value={{ cpf, setCpf }}>
      {children}
    </CpfContext.Provider>
  );
};

const useCpf = () => {
  const context = useContext(CpfContext);
  if (!context) throw new Error("UseCpf must be used within a Provider");
  const { cpf, setCpf } = context;
  return { cpf, setCpf };
};

export { CpfProvider, useCpf };
