import React from "react";

import IconMaterial, { IconProps } from "@material-ui/core/Icon";

interface IconPropsCustom {
  name: string;
}
const IconCustom = ({ name, onClick }: IconProps & IconPropsCustom) => {
  return <IconMaterial onClick={(e) => onClick(e)}>{name}</IconMaterial>;
};
export default IconCustom;
