import React from "react";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: "#bace9c",
    height: "5em",
    padding: "1%",
    display: "flex",
    alignItems: "flex-end",
    fontFamily: "Roboto Condensed Regular",
  },
}));
const HeaderSimples = () => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <h4 style={{ color: "#3c763d" }}>
        Programa Gerador de DAS do Microempreendedor Individual
      </h4>
    </header>
  );
};
export default HeaderSimples;
