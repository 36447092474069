import React from "react";
import { Header, HeaderSimples } from "@components";

import { LayoutProps } from "../interfaces";

const SimplesNacionalLayout = ({ children }: LayoutProps) => {
  return (
    <div>
      <Header />
      <HeaderSimples />
      <main>{children}</main>
    </div>
  );
};

export default SimplesNacionalLayout;
