import React from "react";
import { Header } from "@components";
import { LayoutProps } from "../interfaces";

const MainLayout = ({ children }: LayoutProps) => {
  return (
    <div>
      <Header />
      <main>{children}</main>
    </div>
  );
};

export default MainLayout;
