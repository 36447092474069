import React from "react";
import { Link } from "react-router-dom";
import { IconButton, makeStyles } from "@material-ui/core";
import { Input } from "@material-ui/icons";
import Logo from "@assets/icon-logo.svg";
import List from "@assets/icon-list.svg";
import { Creators as sessionCreators } from "@store/ducks/session";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: "#fff",
  },
}));

export default function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  function logout() {
    dispatch(sessionCreators.logout());
  }
  return (
    <header>
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
        <Link className="navbar-brand text-light" to="/">
          <img
            src={Logo}
            width="85"
            className="logo-inline-block align-top"
            alt=""
            loading="lazy"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsHeader"
          aria-controls="navbarsHeader"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <img
            src={List}
            width="35"
            className="logo-inline-block align-top"
            alt=""
            loading="lazy"
          />
        </button>

        <div className="collapse navbar-collapse" id="navbarsHeader">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <Link className="nav-link text-light" to="/servicos">
                Serviços
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link text-light" to="/servicos">
                Informações
              </Link>
            </li>

            <li className="nav-item ">
              <Link className="nav-link text-light" to="/servicos">
                Sobre
              </Link>
            </li>

            <li className="nav-item ">
              <Link className="nav-link text-light" to="/servicos">
                Contato
              </Link>
            </li>
          </ul>
        </div>

        <IconButton className={classes.signOutButton} onClick={logout}>
          <Input />
        </IconButton>
      </nav>
    </header>
  );
}
