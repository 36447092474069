import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store";
import { Router, Route } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import history from "./history";
import Pages from "./routes/Pages";
import { CssBaseline } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { CpfProvider } from "./hooks/gov";
import { NameProvider } from "./hooks/name";

const App = () => {
  const routes = (
    <Router history={history}>
      <Route component={Pages} />
    </Router>
  );
  return (
    <PersistGate persistor={persistor} loading={null}>
      <StylesProvider>
        <CssBaseline />
        <CpfProvider>
          <NameProvider>{routes}</NameProvider>
        </CpfProvider>
      </StylesProvider>
      <ToastContainer autoClose={3000} />
    </PersistGate>
  );
};

export default App;
