import React, { Fragment } from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootReducerType } from "../store/modules/rootReducer";

interface LayoutProps {
  layout?: React.ElementType;
}
const LoggedInRoute = ({
  component: Component,
  layout: Layout,
  ...otherProps
}: RouteProps & LayoutProps) => {
  const { isAuthenticated } = useSelector(
    (state: RootReducerType) => state.session
  );
  const isAuthenticatedGov = useSelector(
    (state: RootReducerType) => state.session_gov.isAuthenticated
  );

  let redirectPath;
  if (!isAuthenticated) {
    redirectPath = "/login";
  } else if (!isAuthenticatedGov) {
    redirectPath = "/login-gov";
  } else {
    redirectPath = otherProps.location.pathname;
  }
  return (
    <>
      {isAuthenticated && isAuthenticatedGov ? (
        <Layout>
          <Route
            {...otherProps}
            render={(otherProps) => <Component {...otherProps} />}
          />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
            state: { from: otherProps.location },
          }}
        />
      )}
    </>
  );
};

export default LoggedInRoute;
