import { all, takeLatest } from "redux-saga/effects";
import { Types as sessionTypes } from "@store/ducks/session";
import { Types as sessionGovTypes } from "@store/ducks/session_gov";
import { Types as formMeiTypes } from "@store/ducks/form_mei";
import { Types as contributionsTypes } from "@store/ducks/contributions";
import { Types as tableMonthlyTypes } from "@store/ducks/table_monthly";
import { authorize, logout } from "@store/sagas/session";
import { registerGov, authorizeGov } from "@store/sagas/session_gov";
import {
  getByVoter,
  saveFictionalActivities,
  saveWaysOfActions,
  registerFictionalCompany,
  getByAccessCode,
  updateFictionalCompany,
  getByCnpj,
} from "@store/sagas/form_mei";
import {
  billsFromYear,
  declareAnualTrue,
  getBillMonthly,
  getFictionalCompany,
} from "@store/sagas/contributions";
import { generateDataTable, payBill } from "@store/sagas/table_monthly";

export default function* rootSaga() {
  return yield all([
    takeLatest(sessionTypes.SESSION_REQUEST, authorize),
    takeLatest(sessionTypes.SESSION_LOGOUT, logout),
    takeLatest(sessionGovTypes.SESSION_AUTH_REQUEST, authorizeGov),
    takeLatest(sessionGovTypes.SESSION_SIGNUP_REQUEST, registerGov),
    takeLatest(formMeiTypes.GET_VOTER_REQUEST, getByVoter),
    takeLatest(formMeiTypes.UPDATE_WAYS_OF_ACTIONS, saveWaysOfActions),
    takeLatest(formMeiTypes.UPDATE_FICTIONAL_ACTIVITY, saveFictionalActivities),
    takeLatest(
      formMeiTypes.REGISTER_FICTIONAL_COMPANY,
      registerFictionalCompany
    ),
    takeLatest(formMeiTypes.GET_FICTIONAL_COMPANY, getByAccessCode),
    takeLatest(formMeiTypes.GET_FICTIONAL_COMPANY_BY_CNPJ, getByCnpj),
    takeLatest(contributionsTypes.GET_CNPJ_REQUEST, getFictionalCompany),
    takeLatest(formMeiTypes.UPDATE_FICTIONAL_COMPANY, updateFictionalCompany),
    takeLatest(contributionsTypes.GET_BILL_MONTHLY_REQUEST, getBillMonthly),

    takeLatest(tableMonthlyTypes.SAVE_DATA_TABLE_REQUEST, generateDataTable),
    takeLatest(contributionsTypes.DECLARE_ANUAL_REQUEST, billsFromYear),
    takeLatest(contributionsTypes.DECLARE_TRUE_REQUEST, declareAnualTrue),
    takeLatest(tableMonthlyTypes.PAY_SELECTED_LINE_BILL, payBill),
  ]);
}
