import { create } from "apisauce";
import {
  ILoginType,
  IGovLoginType,
  IFictionalCompany,
  IDeclaration,
  IBillMonthly,
} from "types";
import { UserGovAccount } from "@models";

const api = create({
  baseURL: process.env.REACT_APP_API_URL,
  // withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Adiciona o Token no Authorization em todas as requisições que são efetuadas
api.addAsyncRequestTransform((request) => async () => {
  const token = await localStorage.getItem("token");
  if (token) request.headers.Authorization = `Bearer ${token}`;
});

// api.addResponseTransform((response) => {
//   if (!response.ok) {
//     throw response;
//   }
// });

// Login do Governo e Login do Sistema
export const AuthenticationService = {
  login: (data: ILoginType) => {
    return api.post("/login", data);
  },
  loginGov: (data: IGovLoginType) => {
    return api.post(`/gov-account/login/${data.id}`, data);
  },
  registerGov: (data: UserGovAccount, idUser: number) => {
    return api.post(`/gov-account?user_id=${idUser}`, data);
  },
};

// Serviço sobre o cadastro de empresa (FormMEI),FormalizeSe
export const FictionalCompanyService = {
  registerCompany: (data: IFictionalCompany, idGov: number) => {
    return api.post(`/fictional-company/${idGov}`, data);
  },
  getByAccessCode: (data: string, idUser: number) => {
    return api.get(`fictional-company/access-code/${data}/${idUser}`);
  },
  updateFictionalCompany: (data: any, cnpj: string) => {
    return api.put(`fictional-company/update/${cnpj}`, data);
  },
  getByCNPJ: (cnpj: string, userId: number) => {
    return api.get(`fictional-company/cnpj/${cnpj}/${userId}`);
  },
};

// Serviço de Sistema do governo, título de eleitor, FormMEI, FormalizeSe
interface VoterRegistration {
  voter_registration: string;
  idGovAccount: number;
}
export const VoterService = {
  getVoter: ({ idGovAccount, voter_registration }: VoterRegistration) => {
    return api.get(
      `/gov-account?voter_registration=${voter_registration}&id=${idGovAccount}`
    );
  },
};

// Contribuição MENSAL, Declaração ANUAL
interface IAnualCompany {
  year: number;
  declaration: IDeclaration;
  fictionalCompanyId: number;
}

export const ContributionsService = {
  getValidateBill: (id: number) => {
    return api.get(`aikmei/das/validate?id=${id}`);
  },

  getByCnpj: (cnpj: string) => {
    return api.get(`/fictional-company?cnpj=${cnpj}`);
  },

  getBillMonthly: (data: IBillMonthly) => {
    const { fictionalCompanyId, year } = data;

    return api.get(
      `/aikmei/das/declare-month?year=${year}&id=${fictionalCompanyId}`
    );
  },
  billsFromYear: (data: IAnualCompany) => {
    const { year, fictionalCompanyId } = data;

    return api.get(
      `/aikmei/declaration/das-from-year?year=${year}&id=${fictionalCompanyId}`
    );
  },

  declareAnualTrue: (data: IAnualCompany) => {
    const { year, fictionalCompanyId, declaration } = data;
    return api.post(
      `/aikmei/declaration/declare-year?year=${year}&id=${fictionalCompanyId}`,
      { declaration }
    );
  },
  payBill: (billIds: number[]) => {
    return api.put(`/aikmei/das/pay-das`, { idDas: billIds });
  },
  paymentGuide: (id: number) => {
    return api.get<number[]>(`/fictional-company/payment-guide?id=${id}`);
  },
};

export { api };
