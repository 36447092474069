import { BaseAction, IBillMonthly } from "types";
import { call, put } from "redux-saga/effects";
import { ApiResponse } from "apisauce";
import { FictionalCompany, MonthlyBill, UserGovAccount } from "@models";

import { Creators as tableMonthlyCreators } from "@store/ducks/table_monthly";
import { sucessCallback } from "@store/modules/rootReducer";
import history from "@history";
import { toast } from "react-toastify";
import {
  IbillFromYear,
  IFictionalCompanyEntry,
} from "@store/ducks/contributions";
import { removeNoNumerical } from "../../helpers/documents_validate";
import { ErrorException } from "../../models/ErrorException";
import { ContributionsService } from "../../services/api";
import { Types as tableMonthlyTypes } from "../ducks/table_monthly";
import { Types as contributionsTypes } from "../ducks/contributions";

interface IResponse {
  fictional_company: FictionalCompany;
  gov_account: UserGovAccount;
}
interface IResponseBillMonthly {
  bills: MonthlyBill[];
}
interface IReponseAnualCompany {
  bills: MonthlyBill[];
}
interface IResponseVoter extends Error {
  readonly user: UserGovAccount;
}
export function* getFictionalCompany({
  payload,
}: BaseAction<IFictionalCompanyEntry>) {
  const { cnpj, pathname } = payload;
  const nums = removeNoNumerical(cnpj);
  try {
    const response: ApiResponse<IResponse> = yield call(
      ContributionsService.getByCnpj,
      nums
    );
    const navigate = function navigatePage() {
      history.push(pathname, {
        status: "COMPLETED",
      });
    };

    yield sucessCallback(
      response,
      response.data,
      contributionsTypes.GET_CNPJ_SUCCESS,
      navigate
    );
  } catch (err) {
    toast.error(err.message);
    // throw new ErrorException(err.message);
  }
}
export function* getBillMonthly({ payload }: BaseAction<IBillMonthly>) {
  const { fictionalCompanyId, year } = payload;
  try {
    const response: ApiResponse<IResponseBillMonthly> = yield call(
      ContributionsService.getBillMonthly,
      {
        fictionalCompanyId,
        year,
      }
    );
    const showTable = function* show() {
      // calendarCreators.showTable(response.data.value, data);
      // yield put({ type: calendarTypes.SHOW_TABLE, payload: response.data });
      yield put(tableMonthlyCreators.generateDataTable());
    };
    // calendarCreators.showTable(response.data.value, data);
    yield sucessCallback(
      response,
      response.data.bills,
      tableMonthlyTypes.SAVE_DATA_TABLE_SUCCESS,
      showTable
    );
  } catch (err) {
    toast.error(err.message);
    yield put({ type: tableMonthlyTypes.SHOW_TABLE, payload: false });
    yield put({
      type: contributionsTypes.GET_BILL_MONTHLY_ERROR,
      payload: err.message,
    });

    // throw new ErrorException(err.message);
  }
}
export function* billsFromYear({ payload }: BaseAction<IbillFromYear>) {
  try {
    const response: ApiResponse<IReponseAnualCompany> = yield call(
      ContributionsService.billsFromYear,
      payload
    );
    const navigate = function navigatepage() {
      history.push("/declaracao-anual/declarar/coleta-dados", {
        status: "COMPLETED",
        data: payload,
      });
    };
    yield sucessCallback(
      response,
      response.data.bills,
      contributionsTypes.DECLARE_ANUAL_SUCCESS,
      navigate
    );
  } catch (err) {
    toast.error(err.message);
    yield put({
      type: contributionsTypes.DECLARE_ANUAL_ERROR,
      payload: err.message,
    });
  }
}

export function* declareAnualTrue({ payload }: BaseAction<IbillFromYear>) {
  try {
    const response = yield call(ContributionsService.declareAnualTrue, payload);
    const navigate = function navigatepage() {
      history.push("/declaracao-anual/declarar/coleta-dados/resultado", {
        status: "COMPLETED",
        data: payload,
      });
    };
    if (response.status >= 200 && response.status < 300) {
      navigate();
      yield put({
        type: contributionsTypes.DECLARE_TRUE_SUCCESS,
        payload: response.data.declaration,
      });
      toast.success(response.data.message);
    } else {
      throw new ErrorException(response.data.message);
    }
  } catch (err) {
    yield put({
      type: contributionsTypes.DECLARE_TRUE_ERROR,
      payload: err.message,
    });
    toast.error(err.message);
  }
}
