import { cnpj, cpf } from "cpf-cnpj-validator";

export const regexCPF =
  /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/gm;

export function cpfValidate(cpfValue) {
  return cpf.isValid(cpfValue);
}
export function cnpjValidate(cnpjValue) {
  return cnpj.isValid(cnpjValue);
}
export function removeNoNumerical(document) {
  return document.replace(/\D/g, "");
}
