import { call, put, select } from "redux-saga/effects";
import { BaseAction } from "types";
import { Types as sessionGovTypes } from "@store/ducks/session_gov";
import { UserGovAccount } from "@models";
import { ApiResponse } from "apisauce";
import { RootReducerType, sucessCallback } from "@store/modules/rootReducer";
import history from "@history";
import { toast } from "react-toastify";
import { AuthenticationService } from "../../services/api";

interface IResponse extends Error {
  readonly user: UserGovAccount;
}
export function* authorizeGov({ payload }: BaseAction) {
  const { data, pathName } = payload;

  try {
    const response: ApiResponse<IResponse> = yield call(
      AuthenticationService.loginGov,
      data
    );
    const navigate = function navigatePage() {
      history.push(`${pathName}`);
      toast.success("autenticado com sucesso");
    };
    yield sucessCallback(
      response,
      response.data,
      sessionGovTypes.SESSION_SUCCESS,
      navigate
    );

    // To - do
    /**
     * Verify user saved in store
     */
  } catch (error) {
    toast.error(error.message);
    yield put({ type: sessionGovTypes.SESSION_ERROR, payload: error.message });
  }
}
export function* registerGov({ payload }: BaseAction) {
  const { pathName } = payload;

  try {
    const { id } = yield select((state: RootReducerType) => state.session.user);

    const response: ApiResponse<IResponse> = yield call(
      AuthenticationService.registerGov,
      payload.data,
      id
    );
    const navigate = function navigatePage() {
      history.push(`${pathName}`);
      toast.success("cadastrado com sucesso");
    };

    yield sucessCallback(
      response,
      response.data,
      sessionGovTypes.SESSION_SIGNUP_SUCCESS,
      navigate
    );
  } catch (err) {
    toast.error(err.message);
    yield put({ type: sessionGovTypes.SESSION_ERROR, payload: err.message });
  }
}
