import { BaseAction, IFictionalCompany } from "types";
import {
  WayOfActing,
  FictionalCompanyActivity,
  FictionalCompany,
  UserGovAccount,
} from "@models";

export const Types = {
  PURGE: "formMei/PURGE",
  UPDATE_WAYS_OF_ACTIONS: "formMei/UPDATE_WAYS_OF_ACTIONS",
  SUCCESS_UPDATE_WAYS_OF_ACTIONS: "formMei/SUCCESS_UPDATE_WAYS_OF_ACTIONS",
  UPDATE_FICTIONAL_ACTIVITY: "formMei/UPDATE_FICTIONAL_ACTIVITY",
  SUCCESS_UPDATE_FICTIONAL_ACTIVITY:
    "formMei/SUCCESS_UPDATE_FICTIONAL_ACTIVITY",
  REGISTER_FICTIONAL_COMPANY: "formMei/REGISTER_FICTIONAL_COMPANY",
  UPDATE_FICTIONAL_COMPANY: "formMei/UPDATE_FICTIONAL_COMPANY",
  GET_FICTIONAL_COMPANY: "formMei/GET_FICTIONAL_COMPANY",
  GET_FICTIONAL_COMPANY_BY_CNPJ: "formMei/GET_FICTIONAL_COMPANY_BY_CNPJ",
  SUCCESS_FICTIONAL_COMPANY: "formMei/SUCCESS_FICTIONAL_COMPANY",
  ERROR_FICTIONAL_COMPANY: "formMei/ERROR_FICTIONAL_COMPANY",
  GET_VOTER_REQUEST: "formMei/GET_VOTER_REQUEST",
  GET_VOTER_SUCCESS: "formMei/GET_VOTER_SUCCESS",
  GET_VOTER_ERROR: "formMei/GET_VOTER_ERROR",
};

// add data
interface FormMeiState {
  waysOfActions: WayOfActing[];
  fictionalCompany: FictionalCompany | null;
  fictionalCompanyActivity: FictionalCompanyActivity[];
  error: string | null;
  message: string | null;
  govAccount: UserGovAccount; // used in getVoter
}

const INITIAL_STATE: FormMeiState = {
  fictionalCompany: null,
  waysOfActions: [],
  fictionalCompanyActivity: [],
  error: null,
  message: null,
  govAccount: null,
};

const formMei = (state = INITIAL_STATE, { type, payload }: BaseAction) => {
  switch (type) {
    case Types.PURGE:
      return INITIAL_STATE;
    case Types.SUCCESS_UPDATE_WAYS_OF_ACTIONS:
      return { ...state, waysOfActions: payload };
    case Types.SUCCESS_UPDATE_FICTIONAL_ACTIVITY:
      return { ...state, fictionalCompanyActivity: payload };
    case Types.SUCCESS_FICTIONAL_COMPANY:
      return { ...state, fictionalCompany: payload };
    case Types.ERROR_FICTIONAL_COMPANY:
      return { ...state, error: payload };
    case Types.GET_VOTER_SUCCESS:
      return { ...state, govAccount: payload };
    case Types.GET_VOTER_ERROR:
      return { ...state, error: payload };
    default:
      return state;
  }
};
export const Creators = {
  purge: (): BaseAction => ({
    type: Types.PURGE,
  }),
  saveWaysOfActions: (data: WayOfActing[]): BaseAction => ({
    type: Types.UPDATE_WAYS_OF_ACTIONS,
    payload: { data },
  }),
  saveFictionalCompanyActivity: (
    data: FictionalCompanyActivity[]
  ): BaseAction => ({
    type: Types.UPDATE_FICTIONAL_ACTIVITY,
    payload: { data },
  }),
  registerFictionalCompany: (data: IFictionalCompany): BaseAction => ({
    type: Types.REGISTER_FICTIONAL_COMPANY,
    payload: { data },
  }),
  getByVoter: (voter_registration: string): BaseAction => ({
    type: Types.GET_VOTER_REQUEST,
    payload: { voter_registration },
  }),
  verifyFictionalCompany: (data: any): BaseAction => ({
    type: Types.ERROR_FICTIONAL_COMPANY,
    payload: { data },
  }),
  getByAccessCode: (data: string): BaseAction => ({
    type: Types.GET_FICTIONAL_COMPANY,
    payload: { data },
  }),
  updateFictionalCompany: (data: any): BaseAction => ({
    type: Types.UPDATE_FICTIONAL_COMPANY,
    payload: { data },
  }),
  getByCnpj: (data: any): BaseAction => ({
    type: Types.GET_FICTIONAL_COMPANY_BY_CNPJ,
    payload: { data },
  }),
};
export default formMei;
